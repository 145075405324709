<template>
<v-container grid-list-xl fluid>
    <v-layout row wrap>
        <v-flex lg12>
            <v-card>
                <hpo-filter :orderbutton="true" :orderType="2" :listStatus="listStatus" :orderSummary="orderSummary" :headers="headers" :viewName="viewName" v-on:applyFilter="applyFilter" v-on:quickFilter="quickFilter"></hpo-filter>
                <v-widget title="Thống kê chi phí" v-if="selectedOrders.length > 0">
                    <div slot="widget-content">
                        <v-layout class="font-weight-bold" row wrap>
                            <v-flex lg3 sm3 pa-0>
                                <v-card-text>Số đơn hàng:</v-card-text>
                            </v-flex>
                            <v-flex lg9 sm9 pa-0>
                                <v-card-text>{{selectedOrders.length}}</v-card-text>
                            </v-flex>
                        </v-layout>
                        <v-layout class="font-weight-bold" row wrap>
                            <v-flex lg3 sm3 pa-0>
                                <v-card-text>Tổng tiền:</v-card-text>
                            </v-flex>
                            <v-flex lg9 sm9 pa-0>
                                <v-card-text>{{selectedOrders.map(item => item.TotalPriceVND).reduce((prev, curr) => prev + curr, 0) | currency}}</v-card-text>
                            </v-flex>
                        </v-layout>
                        <v-layout class="font-weight-bold" row wrap>
                            <v-flex lg3 sm3 pa-0>
                                <v-card-text>Đã trả:</v-card-text>
                            </v-flex>
                            <v-flex lg9 sm9 pa-0>
                                <v-card-text>{{selectedOrders.map(item => item.Payment).reduce((prev, curr) => prev + curr, 0) | currency}}</v-card-text>
                            </v-flex>
                        </v-layout>
                        <v-layout class="font-weight-bold" row wrap>
                            <v-flex lg3 sm3 pa-0>
                                <v-card-text>Còn thiếu:</v-card-text>
                            </v-flex>
                            <v-flex lg9 sm9 pa-0>
                                <v-card-text>{{(selectedOrders.map(item => item.TotalPriceVND).reduce((prev, curr) => prev + curr, 0) - selectedOrders.map(item => item.Payment).reduce((prev, curr) => prev + curr, 0)) | currency}}</v-card-text>
                            </v-flex>
                        </v-layout>
                    </div>
                </v-widget>
                <v-data-table fixed-header height="750px"   :headers="headers" :items="orders" :must-sort="true" :options.sync="pagination" :server-items-length="total_rows" :footer-props="items_per_page" :loading="loading" :mobile-breakpoint="100" item-key="ID" show-select v-model="selectedOrders">
                    <template v-slot:header="{ props }"><table-header-filter :headers="props.headers" :viewName="viewName" v-on:applyFilter="applyFilter"></table-header-filter></template>
                    <template v-slot:item="{ item, isSelected, select}">
                        <tr :active="isSelected" :id="'r-' + item.ID">
                            <td @click="select(!isSelected)"><v-checkbox primary hide-details :input-value="isSelected"></v-checkbox></td>
                            <td>{{ item.DepositCode ? item.DepositCode : 'KG' + item.ID }}</td>
                            <td class="text-end font-weight-bold">{{ item.OrderWeight | currency('',1)}}</td>
                            <td class="text-end font-weight-bold">{{ item.TotalPriceVND | currency}}</td>
                            <td>{{ item.Username }}</td>
                            <td v-if="adminRole">
                                <v-edit-dialog :return-value.sync="item.CustomerServiceID" large lazy persistent @save="saveDetail({OrderID: item.ID, CustomerServiceID: item.CustomerServiceID})">
                                    <div>{{ getStaffName(item.CustomerServiceID) }}</div>
                                    <template v-slot:input>
                                    <v-select
                                        :items="cs_staffs"
                                        v-model="item.CustomerServiceID"
                                        item-text="FullName"
                                        item-value="ID"
                                    ></v-select>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            <td v-else>{{ getStaffName(item.CustomerServiceID) }}</td>
                            <td>{{ item.CreatedDate | display_date('DD-MM-YYYY HH:mm:ss')}}</td>
                            <td>
                                <v-btn :color="item.Status | order_status_color(1)" small dark depressed outlined block @click="gotoDetail(item.ID, item.UID)">{{ item.Status | order_status(1)}}</v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card>
        </v-flex>
    </v-layout>
    <v-dialog v-model="dialogNew" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="title">Tạo mới đơn hàng</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md pa-0>
            <v-layout row wrap>
              <v-flex lg4 md4 sm12>
                <v-autocomplete label="Khách hàng" v-model="newOrder.UID" :items="customers" item-text="Username" item-value="ID"></v-autocomplete>
              </v-flex>
              <v-flex lg12 md12 sm12 xs12>
                <p class="body-2 font-weight-bold text-red">Danh sách vận đơn</p>
                <v-list>
                  <v-list-item v-for="(item, index) in newOrder.TransactionCodes" :key="item.ID">
                    <v-list-item-content>
                        <v-flex lg4 md4 sm12>
                            <v-text-field label="Mã bill" v-model="item.TransactionCode"></v-text-field>
                        </v-flex>
                        <v-flex lg4 md4 sm12>
                            <v-text-field label="Tên hàng hoá" v-model="item.ProductName"></v-text-field>
                        </v-flex>
                        <v-flex lg2 md2 sm12>
                            <h-currency-input label="Giá trị(¥)" :decimal="1" v-model="item.ProductAmount"></h-currency-input>
                        </v-flex>
                        <v-flex lg2 md2 sm12>
                            <h-currency-input label="Số kiện" :decimal="0" v-model="item.NumberOfPack"></h-currency-input>
                        </v-flex>
                        <v-flex lg12 md12 sm12 xs12>
                            <v-textarea v-model="item.OrderNote" background-color="#fefefe" dense rows="3" outlined label="Ghi chú"></v-textarea>
                        </v-flex>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon @click="newOrder.TransactionCodes.splice(index, 1);">
                        <v-icon>fa-trash-alt</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
                <v-layout row wrap>
                  <v-divider class="ma-0"></v-divider>
                  <v-flex lg2 md2 sm12>
                    <v-text-field label="Mã bill" v-model="newTransaction.TransactionCode"></v-text-field>
                  </v-flex>
                  <v-flex lg4 md4 sm12>
                    <v-text-field label="Tên hàng hoá" v-model="newTransaction.ProductName"></v-text-field>
                  </v-flex>
                  <v-flex lg2 md2 sm12>
                    <h-currency-input label="Giá trị(¥)" :decimal="1" v-model="newTransaction.ProductAmount"></h-currency-input>
                  </v-flex>
                  <v-flex lg2 md2 sm12>
                    <h-currency-input label="Số kiện" :decimal="0" v-model="newTransaction.NumberOfPack"></h-currency-input>
                  </v-flex>
                  <v-flex lg2 md2 sm12>
                    <v-btn icon large @click="appendTransactionCode"><v-icon color="#dc3545">far fa-plus-square</v-icon></v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1 white--text" @click="dialogNew = false">Đóng</v-btn>
          <v-btn color="red darken-1 white--text" @click="createNewOrder">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import {
    mapState
} from "vuex";
import configs from "../../configs";
import _ from "lodash";
import Filtering from "@/components/Filtering";
import { sendErrorNotice, sendSuccessNotice } from "@/commons/utils";
export default {
    components: {
        "hpo-filter": Filtering
    },
    data() {
        let dataType = configs.DATA_TYPE;
        return {
            search: "",
            selectedOrders: [],
            headers: [
                {
                    text: "Mã ký gửi",
                    value: "ID",
                    filterable: true,
                    quickSearch: true,
                    dataType: dataType["String"]
                },
                {
                    text: "Tổng cân nặng",
                    value: "OrderWeight",
                    align: "end",
                    filterable: true,
                    dataType: dataType["Number"]
                },
                {
                    text: "Tổng tiền",
                    value: "TotalPriceVND",
                    align: "end",
                    filterable: true,
                    dataType: dataType["Number"]
                },
                {
                    text: "Khách hàng",
                    value: "Username",
                    quickSearch: true,
                    filterable: true,
                    dataType: dataType["String"]
                },
                {
                    text: "Nhân viên phụ trách",
                    value: "CustomerServiceID",
                    quickSearch: true,
                    filterable: true,
                    values: [],
                    dataType: dataType["Enum"]
                },
                {
                    text: "Ngày đặt hàng",
                    value: "CreatedDate",
                    filterable: true,
                    dataType: dataType["Date"]
                },
                {
                    text: "Trạng thái",
                    value: "Status",
                    filterable: true,
                    align: "center",
                    dataType: dataType["Enum"],
                    values: configs.ORDER_DEPOSIT_STATUS
                }
            ],
            order_deposit_status: configs.ORDER_DEPOSIT_STATUS,
            pagination: {  ...configs.PAGINATION,  sortBy: ["ID"]  },
            items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
            viewName: "deposit_list",
            filterConditions: [],
            quickFilterConditions: [],
            dialogNew: false,
            newTransaction: {
                TransactionCode: '',
                Status: 1,
                UID: 0,
                OrderID: 0,
                PackageType: 1,
                OrderNote: '',
                ProductName: '',
                ProductAmount: 0,
                NumberOfPack: 0
            },
            newOrder: {
                UID: 0,
                Status: 16,
                TransactionCodes: []
            }
        };
    },
    watch: {
        pagination: {
            handler: function (val, oldVal) {
                let filterConditions = this.$store.state.commons.filterConditions;
                this.filterConditions = !_.isEmpty(filterConditions) ? filterConditions[this.viewName] : []
                this.filter_data();
            },
            deep: true
        },
        cs_staffs() {
            let header_item = _.find(this.headers, { value: "CustomerServiceID" });
            if (header_item) {
                header_item.values =
                _.map(this.cs_staffs, (item) => {
                    return {
                    value: item.ID,
                    name: item.FullName,
                    };
                }) || [];
            }
        }
    },
    computed: {
        ...mapState({
            orders: state => state.deposit.all.data,
            total_rows: state => state.deposit.all.total,
            loading: state => state.deposit.loading,
            orderSummary: state => state.deposit.all.summary,
            customers: state => state.customer.master.data,
            cs_staffs: state => _.filter(state.commons.all_users, function(o) {
                return (o.RoleType & configs.ROLE_TYPE.CustomerService) == configs.ROLE_TYPE.CustomerService || (o.RoleType & configs.ROLE_TYPE.Purchase) == configs.ROLE_TYPE.Purchase;
            }),
        }),
        listStatus() {
            const depositStatus = _.filter(configs.ORDER_DEPOSIT_STATUS, s => s.value !== configs.ORDER_DEPOSIT_STATUS_ENUM.hoan_thanh);
            depositStatus.splice(3, 0, { value: -2, name: '', color: '#afa22e' });
            return depositStatus;
        },
        adminRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin;
        },
        staffRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.CustomerService) == configs.ROLE_TYPE.CustomerService || (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Purchase) == configs.ROLE_TYPE.Purchase;
        },
    },
    methods: {
        filter_data: function () {
            var param = {
                pagination: this.pagination,
                filter: this.filterConditions,
                quickFilter: this.quickFilterConditions
            };
            if(this.staffRole) {
                param.filter.push({
                    column: "CustomerServiceID",
                    value: this.$store.state.authentication.user.userid,
                    condition: "equal",
                });
            }
            this.$store.dispatch("deposit/getList", param);
        },
        gotoDetail: function (id, uid) {
            let routeData = this.$router.resolve({
                name: "DepositDetail",
                params: {
                    Pid: id,
                    title: `Chi tiết đơn hàng (#${id})`
                },
                query: {
                    'uid': uid
                }
            });
            window.open(routeData.href, "_blank");
        },
        applyFilter(filterConditions) {
            let sortBy = this.pagination.sortBy
            let sortDesc = this.pagination.sortDesc
            this.pagination = configs.PAGINATION;
            this.pagination.sortBy = sortBy;
            this.pagination.sortDesc = sortDesc;
            this.filterConditions = filterConditions;
            this.filter_data();
        },
        quickFilter(filterConditions) {
            this.quickFilterConditions = filterConditions;
            this.filter_data();
        },
        get_status_color(val, enums) {
            return this.$options.filters.display_color(val, enums);
        },
        getCustomerList() {
            this.$store.dispatch("customer/getCustomerList", {
                is_master: 1,
                fields: ["ID", "Username", "ReceivePlaceID"]
            });
        },
        appendTransactionCode() {
            this.newOrder.TransactionCodes.push(this.newTransaction);
            this.newTransaction = {
                TransactionCode: '',
                Status: 1,
                UID: 0,
                OrderID: 0,
                PackageType: 1,
                OrderNote: '',
                ProductName: '',
                ProductAmount: 0,
                NumberOfPack: 0,
                GuaranteeRate: 0
            }
        },
        createData() {
            this.dialogNew = true;
        },
        createNewOrder() {
            if(this.newOrder.TransactionCodes.length == 0) {
                sendErrorNotice("Hãy nhập danh sách mã bill!");
                return;
            }
            if(this.newOrder.UID == 0) {
                sendErrorNotice("Hãy chọn khách hàng!");
                return;
            }
            const cust = _.find(this.customers, {'ID': this.newOrder.UID});
            var param = {
                pagination: this.pagination,
                filter: this.filterConditions,
                quickFilter: this.quickFilterConditions
            };
            this.newOrder.Username = cust.Username;
            this.newOrder.ReceivePlaceID = cust.ReceivePlaceID;

            this.$store.dispatch("deposit/setDetail", {'data': this.newOrder, 'params': param});
            this.newOrder = {
                UID: 0,
                Status: 16,
                TransactionCodes: []
            };
            this.dialogNew = false;
            sendSuccessNotice('Tạo mới đơn hàng thành công.');
        },
        saveDetail(item) {
            this.$store.dispatch("deposit/setDetail", {'data': item});
        },
        getStaffName(staffId) {
            const staff = _.find(this.cs_staffs, {
                ID: staffId
            });
            return staff ? staff.FullName : "";
        },
    },
    created() {
        this.$store.dispatch("commons/getAllUsers");
    },
    mounted() {
        this.getCustomerList();        
        this.$root.$on("REQUEST_CREATE_DATA", this.createData);
        this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_CREATE_DATA", this.createData);
        this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
    }
};
</script>
